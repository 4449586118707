import * as React from "react"

const Arrow = () => {
  return (
    <svg viewBox="0 0 23.41 14.83">
      <polygon  points="22 8.83 23.41 7.41 16 0 14.59 1.41 20.09 6.91 0 6.91 0 8.91 19.09 8.91 14.59 13.41 16 14.83 21.91 8.91 22 8.91 22 8.83"/>
    </svg>
  )
}

export default Arrow
