import * as React from "react"
import {  motion } from "framer-motion"
const Hero = (props) => {
  
  return (
    <motion.div 
    whileInView={{ opacity: 1, y: 0 }}
    initial={ {opacity: 0 } }
    transition={ { delay: .25 } }
    className="hero">
      <section>
        <h1>{props.title}</h1>
        <h3>{props.sub}</h3>
        <p>{props.paragraph}</p>
      </section>
    
    </motion.div>
  )
} 
export default Hero